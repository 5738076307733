import Chart from 'chart.js/auto'
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns';
import { de } from 'date-fns/locale';

Chart.register(annotationPlugin);

function convertToUnit(value, from = "sg", to = "plato") {
  if (from === "sg" && to === "plato") {
    return (-1 * 616.868) + (1111.14 * value) - (630.272 * value ^ 2) + (135.997 * value ^ 3)
  } else if (from === "plato" && to === "sg") {
    return 1 + (value / (258.6 - ((value / 258.2) * 227.1)))
  }
}

function getOptions(gravity_measures, temperature_measures, beer_og, beer_fg, beer_temp) {
  var min_gravity_measure = Math.min(...gravity_measures)
  var max_gravity_measure = Math.max(...gravity_measures)
  var min_temperature_measure = Math.min(...temperature_measures)
  var max_temperature_measure = Math.max(...temperature_measures)

  var suggestedGravityMax = Math.max(beer_og, max_gravity_measure) + 0.005
  var suggestedGravityMin = Math.min(beer_fg, min_gravity_measure) - 0.005
  var suggestedTemperatureMax = Math.max(beer_temp, max_temperature_measure) + 2
  var suggestedTemperatureMin = Math.min(beer_temp, min_temperature_measure) - 2

  return {
    responsive: true,
    aspectRatio: 1.7,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    layout: {
      padding: {
        left: 0
      }
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: 'day',
          tooltipFormat: 'EEEE, d. MMM HH:mm',
          displayFormats: {
            day: 'd. MMM'
          }
        },
        adapters: {
          date: {
            locale: de
          }
        },
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        ticks: {
          callback: function (val, index) {
            return val.toFixed(3)
          },
          color: 'rgb(253, 138, 138)',
          font: {
            weight: 'bold',
            size: 12,
          },
          padding: 10,
        },
        suggestedMax: suggestedGravityMax,
        suggestedMin: suggestedGravityMin,
        position: 'left',
        display: true,
        fontStyle: 'bold',
        grid: {
          drawTicks: false,
        },
        border: {
          display: false,
        }
      },
      y1: {
        ticks: {
          callback: function (val, index) {
            return val.toFixed(0)
          },
          color: 'rgb(158, 161, 212)',
          font: {
            weight: 'bold',
            size: 12,
          },
          padding: 10,
        },
        suggestedMax: suggestedTemperatureMax,
        suggestedMin: suggestedTemperatureMin,
        position: 'right',
        display: true,
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
        },
        border: {
          display: false,
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipitem) {
            if (tooltipitem.datasetIndex == 0) {
              return "SG " + tooltipitem.raw.toFixed(3)
            } else {
              return "Temp " + tooltipitem.raw.toFixed(1)
            }
          },
        },
        usePointStyle: true,
      },
      annotation: {
        annotations: {
          og_line: {
            type: 'line',
            yMin: beer_og,
            yMax: beer_og,
            borderColor: 'rgb(233, 179, 132)',
            borderWidth: 3,
            borderDash: [3, 8],
            label: {
              content: 'OG',
              color: 'rgb(158, 159, 165)',
              display: true,
              position: 'start',
              backgroundColor: 'rgba(0,0,0,0)',
              yAdjust: -8,
              xAdjust: 0,
            }
          },
          fg_line: {
            type: 'line',
            yMin: beer_fg,
            yMax: beer_fg,
            borderColor: 'rgb(121, 172, 120)',
            borderWidth: 3,
            borderDash: [3, 8],
            label: {
              content: 'FG',
              color: 'rgb(158, 159, 165)',
              display: true,
              position: 'start',
              backgroundColor: 'rgba(0,0,0,0)',
              yAdjust: 10,
              xAdjust: 0,
            }
          },
          temperature_line: {
            type: 'line',
            yMin: beer_temp,
            yMax: beer_temp,
            yScaleID: 'y1',
            borderColor: 'rgb(80, 80, 80)',
            borderWidth: 3,
            borderDash: [3, 8],
            label: {
              content: 'Temp',
              color: 'rgb(158, 159, 165)',
              display: true,
              position: 'end',
              backgroundColor: 'rgba(0,0,0,0)',
              yAdjust: -8,
              xAdjust: 0,
            }
          }
        }
      }
    }
  }
}

async function requestData(beer_name) {
  var response = await fetch('/api/tilt/' + beer_name)
  var beer_data = await response.json()
  return beer_data
}

function createOrUpdateChart(beer_data) {
  var chart = Chart.getChart('beers')

  updateBrewParameters(
    beer_data['original_gravity'],
    beer_data['final_gravity'],
    beer_data['fermentation_temperature'],
    beer_data['gravity_measures'].at(-1),
    beer_data['timepoints'].at(-1)
  )

  if (chart === undefined) {
    new Chart(document.getElementById('beers').getContext('2d'), {
      type: 'line',
      data: {
        labels: beer_data["timepoints"],
        datasets: [
          {
            label: "Restextrakt",
            data: beer_data['gravity_measures'],
            borderColor: 'rgb(253, 138, 138)',
            backgroundColor: 'rgb(253, 138, 138)',
            tension: 0.0,
            cubicInterpolationMode: 'monotone',
            yAxisID: 'y',
            pointRadius: 0,
          },
          {
            label: "Temperatur",
            data: beer_data['temperature_measures'],
            borderColor: 'rgb(158, 161, 212)',
            backgroundColor: 'rgb(158, 161, 212)',
            tension: 0.0,
            cubicInterpolationMode: 'monotone',
            yAxisID: 'y1',
            pointRadius: 0,
            pointStyle: 'rect',
          },
        ]
      },
      options: getOptions(beer_data['gravity_measures'], beer_data['temperature_measures'], beer_data["original_gravity"], beer_data['final_gravity'], beer_data['fermentation_temperature'])
    });
  } else {
    chart.data.datasets[0].data = beer_data['gravity_measures']
    chart.data.datasets[1].data = beer_data['temperature_measures']
    chart.data.labels = beer_data['timepoints']
    chart.options = getOptions(beer_data['gravity_measures'], beer_data['temperature_measures'], beer_data['original_gravity'], beer_data['final_gravity'], beer_data['fermentation_temperature'])
    chart.update()
  }
}

function updateBrewParameters(og, fg, temp, current_sg, latest_timestamp) {
  var beer_og_element = document.getElementById('beers-og')
  var beer_fg_element = document.getElementById('beers-fg')
  var beer_temp_element = document.getElementById('beers-temp')
  var beer_alcohol_element = document.getElementById('abv')
  var beer_sevg_element = document.getElementById('sevg')
  var beer_lastest_timestamp_element = document.getElementById('latest-timestamp')

  beer_og_element.value = og.toFixed(3)
  beer_fg_element.value = fg.toFixed(3)
  beer_temp_element.value = temp.toFixed(1)
  beer_alcohol_element.textContent = Math.max(((og - current_sg) * 131.25), 0.0).toFixed(1)
  beer_sevg_element.textContent = (Math.max((og - current_sg) / (og - 1), 0.0) * 100.0).toFixed(0)
  beer_lastest_timestamp_element.textContent = new Date(latest_timestamp).toLocaleTimeString("de-DE", { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })
}

function requestDataAndUpdateChart(beer_name) {
  requestData(beer_name).then(function (beer_data) { createOrUpdateChart(beer_data) })
}

function updateUnit() {
  console.log(this.value)
}

(async function () {
  var beer_name_element = document.getElementById('multi-beer-name')

  // Setup event listeners
  beer_name_element.addEventListener('change', function () {
    requestDataAndUpdateChart(this.value)
  })

  document.getElementById('beers-og').addEventListener('change', function () {
    var beer_name = beer_name_element.value
    var new_og = parseFloat(this.value)
    requestData(beer_name).then(function (beer_data) {
      beer_data['original_gravity'] = parseFloat(new_og)
      createOrUpdateChart(beer_data)
    })

    // Update OG in database
    fetch('/api/tilt/' + beer_name + '/update-og', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        original_gravity: parseFloat(new_og)
      })
    })
  })

  document.getElementById('beers-fg').addEventListener('change', function () {
    var beer_name = beer_name_element.value
    var new_fg = parseFloat(this.value)
    requestData(beer_name).then(function (beer_data) {
      beer_data['final_gravity'] = parseFloat(new_fg)
      createOrUpdateChart(beer_data)
    })

    // Update FG in database
    fetch('/api/tilt/' + beer_name + '/update-fg', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        final_gravity: parseFloat(new_fg)
      })
    })
  })

  document.getElementById('beers-temp').addEventListener('change', function () {
    var beer_name = beer_name_element.value
    var new_temp = parseFloat(this.value)
    requestData(beer_name).then(function (beer_data) {
      beer_data['fermentation_temperature'] = parseFloat(new_temp)
      createOrUpdateChart(beer_data)
    })

    // Update temperature in database
    fetch('/api/tilt/' + beer_name + '/update-temp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fermentation_temperature: parseFloat(new_temp)
      })
    })
  })

  // var unit_radios = document.getElementById('unit-form').optionsUnit
  // for (var i = 0; i < unit_radios.length; i++) {
  //   unit_radios[i].addEventListener('change', updateUnit);
  // }

  // Create initial chart
  var initial_beer_name = beer_name_element.value
  requestDataAndUpdateChart(initial_beer_name)
})();